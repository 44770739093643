import moment, { Moment } from 'moment'

import { post, get } from './base/base-http-service'
import {
  clearLocalStorage,
  getValueForKeyInLocalStorage,
  storeInLocalStorage,
} from './local-storage-service'
import {
  userKey,
  idToken,
  accessToken,
  refreshToken,
  redirectFlag,
  expires,
  username,
  loginStackRelativePath,
} from '../shared/constants'
import { Environment, LoggedUser } from '../shared/models'

import jwtDecode from 'jwt-decode'
import axios from 'axios'

const checkAuthExpirationInterval = 1000 * 60 * 1
const expiresSoonPeriod = 1000 * 60 * 5

export const getEnvironment = async (): Promise<Environment | null> => {
  const environment = localStorage.getItem('environment')
  if (!environment) {
    const config = await axios.get('/configuration.json')
    localStorage.setItem('environment', JSON.stringify(config))
  }
  const configuration = localStorage.getItem('environment')
  return configuration ? (JSON.parse(configuration) as Environment) : null
}

export const checkIfUsernameExists = (): boolean => {
  return !!localStorage.getItem('username')
}

export const checkIfTokensExists = (): boolean => {
  return (
    !!getValueForKeyInLocalStorage(idToken) &&
    !!getValueForKeyInLocalStorage(accessToken)
  )
}

export const getUsername = (): any => {
  getValueForKeyInLocalStorage(username)
}

export const isUserAccessLevelValid = (accessLevelRoles: string[]): boolean => {
  return accessLevelRoles.includes(getUserRole())
}

export const getUserRole = (): string => {
  const user = getValueForKeyInLocalStorage(userKey) as LoggedUser
  if (user) {
    return user.role
  }
  return ''
}

export const getUserId = (): number => {
  const user = getValueForKeyInLocalStorage(userKey) as LoggedUser
  if (user) {
    return user.userId
  }
  return 0
}
export class AuthService {
  static instance: AuthService
  loginStackUrl: string
  lastRefreshedOn: moment.Moment
  refreshTimerHandle: any

  private constructor() {
    this.loginStackUrl = `${window.location.origin}${loginStackRelativePath}`
    this.lastRefreshedOn = moment()
    if (this.isLoggedIn()) {
      this.startAuthRefreshTimer()
    }
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService()
    }
    return AuthService.instance
  }

  getSessionFromAPI = async (): Promise<any> => {
    return await get('session/')
  }

  handleLogout = async (): Promise<any> => {
    return await post('session/', {})
  }

  getAuthUrl = async (): Promise<string | null> => {
    const environment = await getEnvironment()

    if (environment) {
      return environment.AUTH_URL
    }
    if (localStorage.getItem('AUTH_URL')) {
      return localStorage.getItem('AUTH_URL')
    }
    return ''
  }

  redirectToLoginUI = (): any => {
    window.location.replace(this.loginStackUrl)
  }

  isSessionExists = (): boolean => {
    return !!getValueForKeyInLocalStorage(idToken)
  }

  isAuthRefreshTimerRunning = (): boolean => {
    return !!this.refreshTimerHandle
  }

  isLoggedIn = (): boolean => {
    return moment().isBefore(this.getExpiration())
  }

  isUserInfoAvailable = (): boolean => {
    return !!getValueForKeyInLocalStorage(userKey)
  }

  isLoggedOut = (): boolean => {
    return !this.isLoggedIn()
  }

  isRedirectFromLogin = (): boolean => {
    return getValueForKeyInLocalStorage(redirectFlag) as boolean
  }

  willExpireSoon = (): boolean => {
    return (
      this.getExpiration().diff(moment(), 'millisecond', true) <
      expiresSoonPeriod
    )
  }

  resetRedirectFromLoginFlag = (): void => {
    storeInLocalStorage(redirectFlag, 'false')
  }

  login = (): void => {
    this.redirectToLoginUI()
  }

  onLoginSuccess = (): void => {
    this.lastRefreshedOn = moment()
    if (!this.isAuthRefreshTimerRunning()) {
      this.startAuthRefreshTimer()
    }
    this.resetRedirectFromLoginFlag()
  }

  tokenExpired = (): void => {
    clearLocalStorage()
  }

  logout = async (): Promise<void> => {
    await this.handleLogout()
    clearLocalStorage()
    this.stopAuthRefreshTimer()
    this.redirectToLoginUI()
  }

  getExpiration = (): Moment => {
    const expiresAt = getValueForKeyInLocalStorage(expires) as number
    return moment.unix(expiresAt)
  }

  refreshSession = async (callback: Function): Promise<void> => {
    const refreshTokenValue: string =  getValueForKeyInLocalStorage(refreshToken) as string
    const authUrl = await this.getAuthUrl()

    if (!refreshTokenValue) {
      this.logout()
      return callback(null)
    } else {
      try {
        const refreshResult = await axios.post(`${authUrl}refresh`, {
          refreshToken: refreshTokenValue,
        })
        this.setAuthResult(
          refreshResult.data.idToken,
          refreshResult.data.accessToken
        )
        return callback(null)
      } catch (err) {
        return callback(err)
      }
    }
  }

  startAuthRefreshTimer(): void {
    this.refreshTimerHandle = setInterval(() => {
      if (this.lastRefreshedOn.isBefore(moment(), 'day')) {
        this.logout()
      } else {
        if (this.willExpireSoon()) {
          this.refreshSession(err => {
            if (err) {
              this.logout()
            } else {
              this.lastRefreshedOn = moment()
            }
          })
        }
      }
    }, checkAuthExpirationInterval)
  }

  stopAuthRefreshTimer(): void {
    clearTimeout(this.refreshTimerHandle)
    this.refreshTimerHandle = null
  }

  setAuthResult = (
    idTokenValue: any,
    accessTokenValue: any,
    refreshTokenValue?: any
  ): void => {
    const decodedToken: any = jwtDecode(idTokenValue.jwtToken)
    const expiresAt = moment(decodedToken.exp)
    storeInLocalStorage(idToken, idTokenValue.jwtToken)
    storeInLocalStorage(accessToken, accessTokenValue.jwtToken)
    storeInLocalStorage(expires, JSON.stringify(expiresAt.valueOf()))
    if (refreshTokenValue) {
      storeInLocalStorage(refreshToken, refreshTokenValue.token)
    }
  }
}
